<template>
  <div id="equipment">
    <div class="wrapper">
      <div class="all_navBar">
        <router-link v-for="(item, ind) in all_navBar"
                     :key="ind"
                     :to="item.path">
          <span :id="SelectedInd == ind ? 'Selected' : ''"
                :class="
              ind == 0
                ? 'first_child'
                : ind == all_navBar.length - 1
                ? 'last_child'
                : ''
            "
                @click="Selected(ind)">{{ item.name }}</span>
        </router-link>
      </div>
      <div class="add"
           v-if="true"
           @click="addUavModal = !addUavModal">
        添加设备
      </div>
    </div>
    <div class="conent">
      <router-view :teamID="teamID"></router-view>
    </div>
    <!--弹框区-->
    <Modal v-model="addUavModal"
           class-name="addUAV-modal"
           width="1000"
           @on-cancel="cancelAddUavModal">
      <p slot="header"
         style="text-align: center">
        <span style="font-size: 20px">注册无人机</span>
      </p>
      <div class="content">
        <Form ref="addUav"
              :model="addUav"
              :rules="addUav_ruleInline"
              label-position="top">
          <div class="container">
            <div class="left">
              <h1>无人机信息</h1>
              <FormItem label="单位名称"
                        prop="device_name">
                <!-- <Input v-model="addUav.device_name"
                       maxlength="30"
                       show-word-limit></Input> -->
                      <TeamListCascader
                  @change_Team="changeTeamData"
                  ref="TeamListCascader"
                  class="addTeamInput"
                  style="width: 100%"
                />
              </FormItem>
              <!-- <FormItem label="品牌"
                        prop="brand">
                <Input v-model="addUav.brand"
                       maxlength="30"
                       show-word-limit></Input>
              </FormItem> -->
              <FormItem label="型号"
                        prop="model">
                <Cascader v-model="addUav.model"
                          :data="UAVTypeList">
                  <!-- <Option v-for="(item,index) in UAVTypeList"
                          :value="item"
                          :key="index">{{
                    item
                  }}</Option> -->
                </Cascader>
              </FormItem>
              <FormItem label="产品序列号"
                        prop="serial_code">
                <Input v-model="addUav.serial_code"
                       maxlength="40"
                       show-word-limit></Input>
              </FormItem>
              <FormItem label="飞控序列号"
                        prop="flycontrol">
                <Input v-model="addUav.flycontrol"
                       maxlength="40"
                       show-word-limit></Input>
              </FormItem>
              <FormItem label="无人机类型"
                        prop="uav_type">
                <Select v-model="addUav.uav_type">
                  <Option value="多旋翼">多旋翼</Option>
                  <Option value="固定翼">固定翼</Option>
                  <Option value="垂直起降">垂直起降</Option>
                </Select>
              </FormItem>
              <FormItem label="中国民航局民用无人机登记号"
                        prop="registration">
                <Input v-model="addUav.registration"
                       maxlength="40"
                       show-word-limit></Input>
              </FormItem>
              <FormItem label="标注"
                        prop="remark">
                <Input v-model="addUav.remark"
                       maxlength="20"
                       show-word-limit></Input>
              </FormItem>
            </div>
            <div class="Divider"></div>
            <div class="right">
              <h1>保险类型</h1>
              <FormItem label="是否有保险">
                <RadioGroup v-model="whether_insurance">
                  <Radio label="有保险">有保险</Radio>
                  <Radio label="无保险">无保险</Radio>
                </RadioGroup>
              </FormItem>
              <FormItem label="保险公司">
                <Input v-model="addUav.insu_company"
                       :disabled="whether_insurance == '有保险' ? false : true"
                       maxlength="30"
                       show-word-limit></Input>
              </FormItem>
              <FormItem label="保险单号">
                <Input v-model="addUav.insu_no"
                       :disabled="whether_insurance == '有保险' ? false : true"
                       maxlength="40"
                       show-word-limit></Input>
              </FormItem>
              <div style="display: flex; justify-content: space-between">
                <FormItem label="购买日期"
                          style="width: 45%">
                  <DatePicker type="date"
                              placeholder="选择购买日期"
                              v-model="addUav.buydate"
                              :editable="false"
                              style="width: 100%"
                              :disabled="whether_insurance == '有保险' ? false : true"></DatePicker>
                </FormItem>
                <FormItem label="到期日期"
                          style="width: 45%">
                  <DatePicker type="date"
                              placeholder="选择保险到期日期"
                              :editable="false"
                              v-model="addUav.validityday"
                              style="width: 100%"
                              :disabled="whether_insurance == '有保险' ? false : true"></DatePicker>
                </FormItem>
              </div>
              <FormItem label="保险种类">
                <Input v-model="addUav.insutype"
                       :disabled="whether_insurance == '有保险' ? false : true"
                       maxlength="30"
                       show-word-limit></Input>
              </FormItem>
              <FormItem label="销售公司">
                <Input v-model="addUav.sales_company"
                       :disabled="whether_insurance == '有保险' ? false : true"
                       maxlength="40"
                       show-word-limit></Input>
              </FormItem>
            </div>
          </div>
        </Form>
      </div>
      <div slot="footer"
           style="text-align: center">
        <Button type="info"
                size="large"
                style="width: 300px; letter-spacing: 4px"
                @click="addUav_ok">注册</Button>
      </div>
    </Modal>
  </div>
</template>

<script>
import { DeviceList, DeviceType, DeviceAdd } from '@/utils/javaApi.js';
import TeamListCascader from '@/components/statistics/commonComponents/TeamCascader_task.vue'
export default {
  name: 'equipment',
  components: {TeamListCascader},
  data: function () {
    return {
      teamID: -1, // 团队id
      userType: '',
      userInfo: {},
      all_navBar: [
        {
          name: '无人机管理',
          path: '/index/equipment/UavManage',
        },
        {
          name: '电池管理',
          path: '/index/equipment/BatteryManage',
        },
        // {
        //     name: "使用记录",
        //     path: "/index/equipment/UsageLog"
        // },
        {
          name: '设备保养管理',
          path: '/index/equipment/EquipmentMaintenance',
        },
        // {
        //   name: '警用适航证',
        //   path: '/index/equipment/table',
        // },
      ],
      SelectedInd: 0,
      addUavModal: false,
      addUav: {
        team_id: '',
        device_name: '',
        brand: 'DJI',
        model: [],
        serial_code: '',
        flycontrol: '',
        uav_type: '',
        registration: '',
        remark: '',
        is_insurance: '',
        insu_company: '',
        insu_no: '',
        buydate: '',
        validityday: '',
        insutype: '',
        sales_company: '',
      },
      addUav_ruleInline: {
        device_name: [
          { required: true, message: '请填写单位名称', trigger: 'blur' },
        ],
        // brand: [
        //   { required: true, message: '请填写无人机品牌', trigger: 'blur' },
        // ],
        model: [
          {
            required: true,
            message: '请选择无人机型号',
            trigger: 'change',
            pattern: /.+/,
          },
        ],
        serial_code: [
          { required: true, message: '请填写产品序列号', trigger: 'blur' },
        ],
        flycontrol: [
          { required: true, message: '请填写飞控序列号', trigger: 'blur' },
        ],
        uav_type: [
          {
            required: true,
            message: '请选择无人机类型',
            trigger: 'change',
            pattern: /.+/,
          },
        ],
      },
      UAVTypeList: [],
      whether_insurance: '无保险',
    };
  },
  mounted() {
    let user_id = JSON.parse(sessionStorage.getItem('user_info')).user_id;
    if (user_id == 360) {
      this.all_navBar = [
        {
          name: '无人机管理11',
          path: '/index/equipment/UavManage',
        },
        {
          name: '电池管理',
          path: '/index/equipment/BatteryManage',
        },
        {
          name: '设备保养管理',
          path: '/index/equipment/EquipmentMaintenance',
        },
        // {
        //   name: '警用适航证',
        //   path: '/index/equipment/table',
        // },
      ];
    }
    this.userType = sessionStorage.getItem('userType');
    this.userInfo = JSON.parse(sessionStorage.getItem('user_info'));
    // this.teamID = this.userInfo.team_id;
    if (sessionStorage.getItem('team_id') === null) {
      this.teamID = -1;
    } else {
      this.teamID = Number(sessionStorage.getItem('team_id'));
    }
    this.$_bus.$off('change_Team').$on('change_Team', (value) => {
      this.teamID = value;
    });
    this.$post(DeviceType()).then((res) => {
      if (res.code == 1) {
        res.data.forEach((item, index) => {
          item.label = item.brand;
          item.value = index;
          item.children.map((el) => {
            el.label = el.model;
            el.value = el.classId;
          });
        });
        this.UAVTypeList = res.data;
      } else {
        this.$Message.error(res.msg_customer);
      }
    });
    //刷新页面定位到路由指定的nav
    let activePath = this.$route.path;
    this.all_navBar.forEach((item, ind) => {
      if (item.path == activePath) {
        this.Selected(ind);
      }
    });
    [
      { path: '/index/equipment/table/tableOne' },
      { path: '/index/equipment/table/tableTwo' },
      { path: '/index/equipment/table/tableThree' },
      { path: '/index/equipment/table/tableFour' },
      { path: '/index/equipment/table/tableFive' },
      { path: '/index/equipment/table/tableSix' },
    ].forEach((ele) => {
      if (ele.path == activePath) {
        this.Selected(3);
      }
    });
  },
  methods: {
    changeTeamData(val, data) {
      this.addUav.device_name = data[0].label
    },
    //选中nav
    Selected(ind) {
      this.SelectedInd = ind;
    },
    addUav_ok() {
      this.$refs['addUav'].validate((valid) => {
        if (valid) {
          var data = {
            team_id: this.teamID,
            device_name: this.addUav.device_name,
            // brand: this.addUav.brand,
            class_id: this.addUav.model[1],
            serial_code: this.addUav.serial_code,
            flycontrol: this.addUav.flycontrol,
            uav_type: this.addUav.uav_type,
            registration: this.addUav.registration,
            is_insurance: this.whether_insurance == '有保险' ? 1 : 0,
            remark: this.addUav.remark
          };
          if (data.team_id == -1) {
            data.team_id = this.userInfo.team_id;
          }
          // 如果有保险
          if (data.is_insurance == 1) {
            if (this.addUav.insu_company == '') {
              this.$Message.warning('保险公司不能为空');
              return;
            }
            if (this.addUav.insu_no == '') {
              this.$Message.warning('保险单号不能为空');
              return;
            }
            if (this.addUav.insutype == '') {
              this.$Message.warning('保险类型不能为空');
              return;
            }
            if (this.addUav.sales_company == '') {
              this.$Message.warning('保险销售公司不能为空');
              return;
            }
            data.insu_company = this.addUav.insu_company;
            data.insu_no = this.addUav.insu_no;
            data.buydate = this.$moment(this.addUav.buydate).format(
              'YYYY-MM-DD'
            );
            data.validityday = this.$moment(this.addUav.validityday).format(
              'YYYY-MM-DD'
            );
            data.insutype = this.addUav.insutype;
            data.sales_company = this.addUav.sales_company;
            if (data.buydate == 'Invalid date') {
              this.$Message.warning('保险购买日期不能为空');
              return;
            }
            if (data.validityday == 'Invalid date') {
              this.$Message.warning('保险有效期不能为空');
              return;
            }
          }
          this.$post(DeviceAdd(), data).then((res) => {
            console.log(res);
            if (res.code == 1) {
              this.$_bus.$emit('loadUavList', true);
              this.addUav = {
                team_id: '',
                device_name: '',
                brand: 'DJI',
                model: [],
                serial_code: '',
                flycontrol: '',
                uav_type: '',
                remark: '',
                registration: '',
                is_insurance: '',
                insu_company: '',
                insu_no: '',
                buydate: '',
                validityday: '',
                insutype: '',
                sales_company: '',
              };
              this.addUavModal = false;
              this.$Message.info(res.msg_customer);
              this.$refs.TeamListCascader.clearInput()
            } else {
              this.$Message.error(res.msg_customer);
            }
          });
        }
      });
    },
    cancelAddUavModal() {
      console.log('啊啊啊');
      this.$refs.TeamListCascader.clearInput()
      this.addUav = {
        team_id: '',
        device_name: '',
        brand: 'DJI',
        model: '',
        serial_code: '',
        flycontrol: '',
        uav_type: '',
        remark: '',
        registration: '',
        is_insurance: '',
        insu_company: '',
        insu_no: '',
        buydate: '',
        validityday: '',
        insutype: '',
        sales_company: '',
      };
    },
  },
};
</script>

<style scoped lang="scss">
#equipment {
  width: 100%;
  height: 100%;
  position: relative;
  padding: 24px 20px;
  background-color: #3C3D43;
  .wrapper {
    margin-bottom: 14px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .all_navBar {
      display: flex;
      span {
        display: block;
        width: 148px;
        height: 34px;
        background-color: #313131;
        line-height: 34px;
        text-align: center;
        border-right: 1px solid #000;
        font-size: 14px;
        color: #fff;
      }
      .first_child {
        border-radius: 8px 0px 0px 8px;
      }
      .last_child {
        border-radius: 0px 8px 8px 0px;
      }
      #Selected {
        background-color: #409eff;
        color: #fff;
      }
    }
    .add {
      width: 82px;
      height: 35px;
      line-height: 35px;
      background: #4c98ff;
      margin-right: 10px;
      cursor: pointer;
      text-align: center;
      color: #fff;
      font-size: 14px;
    }
  }
  .conent {
    width: 100%;
    margin: 12px auto 0;
  }
}
/deep/.addUAV-modal {
  .content {
    padding: 10px 20px;
    .container {
      width: 100%;
      display: flex;
    }
    .left {
      h1 {
        font-size: 26px;
        margin-bottom: 30px;
      }
      width: 50%;
      .UAVimg {
        width: 100%;
        height: 244px;
        display: flex;
        justify-content: center;
        align-content: center;
        img {
          width: 428px;
          height: auto;
        }
      }
    }
    .Divider {
      width: 2px;
      margin: 10px 20px;
      background-color: #f8f8f9;
    }
    .right {
      h1 {
        font-size: 26px;
        margin-bottom: 30px;
      }
      width: 50%;
    }
  }
  .ivu-modal-footer {
    padding: 10px 18px 10px 18px;
  }
}
</style>
